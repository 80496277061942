import { createContext, useState } from "react";

const SchoolContext = createContext();

export default SchoolContext;

/**
    ** Бүрэлдэхүүн сургуулийн id-г авах context
    * @param {String}           setSchool           Сонгосон сургуулийн id-г авна
    * @param {String}           school_id           Тухайн сургуулийн id
*/

export const SchoolContextProvider = ({ children }) => {

    const [ school_id, setSchool ] = useState('')

    return (
        <SchoolContext.Provider value={{ school_id, setSchool }}>
            {children}
        </SchoolContext.Provider>
    );
};
